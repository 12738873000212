/* You can add global styles to this file, and also import other style files */
@root-entry-name: default;

@import '../node_modules/ng-zorro-antd/style/entry.less';

@import '../node_modules/ng-zorro-antd/button/style/entry.less';
@import '../node_modules/ng-zorro-antd/icon/style/entry.less';
@import '../node_modules/ng-zorro-antd/avatar/style/entry.less';
@import '../node_modules/ng-zorro-antd/divider/style/entry.less';
@import '../node_modules/ng-zorro-antd/grid/style/entry.less';
@import '../node_modules/ng-zorro-antd/input/style/entry.less';
@import '../node_modules/ng-zorro-antd/layout/style/entry.less';
@import '../node_modules/ng-zorro-antd/form/style/entry.less';
@import '../node_modules/ng-zorro-antd/list/style/entry.less';
@import '../node_modules/ng-zorro-antd/message/style/entry.less';
@import '../node_modules/ng-zorro-antd/select/style/entry.less';
@import '../node_modules/ng-zorro-antd/spin/style/entry.less';
@import '../node_modules/ng-zorro-antd/statistic/style/entry.less';
@import '../node_modules/ng-zorro-antd/tabs/style/entry.less';
@import '../node_modules/ng-zorro-antd/tag/style/entry.less';
@import '../node_modules/ng-zorro-antd/tooltip/style/entry.less';
@import '../node_modules/ng-zorro-antd/typography/style/entry.less';
@import '../node_modules/ng-zorro-antd/result/style/entry.less';
@import '../node_modules/ng-zorro-antd/image/style/entry.less';
@import '../node_modules/ng-zorro-antd/menu/style/entry.less';
@import '../node_modules/ng-zorro-antd/card/style/entry.less';
@import '../node_modules/ng-zorro-antd/alert/style/entry.less';

@border-radius-base: 0.2rem;
@primary-color: #1f2ade;
@layout-body-background: #ffffff;
@tabs-bar-margin: 16px 0;
@layout-footer-padding: 16px 20px;
@card-head-background: #f5f5f5;
@heading-1-size: 32px;
@heading-2-size: 26px;
@heading-3-size: 20px;

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

.with-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-auto {
  height: auto;
}

.color-white {
  color: white;
}

.square {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center center;
}

.opacity-03 {
  opacity: 0.3;
}

.display-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.lg-sub-html {
  background: rgba(0, 0, 0, 0.45);
  max-height: 20vh;
  overflow: scroll;
  text-align: left;
  padding: 10px 20px;
}

.lg-sub-html .caption {
  display: block;
  font-size: 12px;
  line-height: 12px;
  padding: 8px 6px;
  font-weight: 300;
  white-space: pre-line;
}

.lg-sub-html .user {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 4px 6px;
  font-weight: 700;
}

article p {
  text-align: justify;
}

.section {
  padding: 16px 0;
}

.section.three-quarters {
  min-height: 75vh;
}

.section.three-quarters:first-child {
  min-height: calc(75vh - 47px - 16px);
}

.section.full {
  min-height: 100vh;
}

.section.full:first-child {
  min-height: calc(100vh - 47px - 16px);
}

.section.half {
  min-height: 50vh;
}

.bg-dark-grey {
  background: #f5f5f5;
}

.bg-light-grey {
  background: #fafafa;
}

.fade-in {
  animation: fade-in 750ms;
}

.grecaptcha-badge {
  visibility: hidden;
}

.index:before {
  display: inline-block;
  margin-right: 8px;
  width: 20px;
}

.index.first:before {
  content: '1️⃣';
}

.index.second:before {
  content: '2️⃣';
}

.index.third:before {
  content: '3️⃣';
}

.index.fourth:before {
  content: '4️⃣';
}

.index.fifth:before {
  content: '5️⃣';
}

.index.sixth:before {
  content: '6️⃣';
}
